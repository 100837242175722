import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineFilePdf } from 'react-icons/ai';
import styles from './searchCard.module.css';


export const SearchCard = ({ index, data }) => {
  // console.log(data);
  const navigate = useNavigate();
  // One-liner to safely retrieve the image URL
  const imageUrl = data?.imageUrls?.[0]?.url || data?.epdFrontPageURL?.url;
  const handleclick = () => {
    console.log("clicked");
    return navigate(`/produkt/${data.id}?from=search`);
  };





  return (
    <li className={styles.foundProductItem} key={index} onClick={handleclick} >
      <img src={imageUrl} alt={data.name || "Product Image"} className={styles.img} />
      <div className={styles.productInfo}>
        <span title={data.productName} className={styles.productName}>{data.productName}</span>
        <p className={styles.producerName}>{data.producerName}</p>
        <a
          href={data.epdLink}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkStyle}
          onClick={(e) => e.stopPropagation()}
        >
          <AiOutlineFilePdf style={{ fontSize: '24px', color: 'red' }} />
          <p className={styles.productLink}>{data.epdid}</p>
        </a>
      </div>
    </li>
  );
};



export const SearchBarPopup = ({ query, search, category}) => {
  const ulRef = useRef(null);
  const timeoutRef = useRef(null); // Track debounce timeout
  const [data, setData] = useState({ manualTypedData: [] });
  const [offset, setOffset] = useState(0);
  const [currentSearch, setCurrentSearch] = useState(search);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const fetchData = async () => {
        try {
          if (search !== currentSearch) {
            setOffset(0);
            setData({ manualTypedData: [] });
            setCurrentSearch(search);
          }
          // console.log("Search:", search);
          const result = await query(search, 0, category);
          // console.log("Result:", result);

          setData({ manualTypedData: result || [] });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }, 5); // Debounce only for search changes

    return () => clearTimeout(timeoutRef.current);
  }, [query, search]);

  // Separate effect for offset changes (Pagination)
  useEffect(() => {
    if (offset === 0) return; // Don't re-fetch when offset is reset

    const fetchMoreData = async () => {
      try {
        const result = await query(search, offset);
        // console.log("Paginated Result:", result);

        setData((prevData) => ({
          manualTypedData: [...(prevData.manualTypedData || []), ...(result || [])]
        }));
      } catch (error) {
        console.error("Error fetching paginated data:", error);
      }
    };

    fetchMoreData();
  }, [offset]);

  // Handle infinite scrolling
  const handleScroll = () => {
    const ulElement = ulRef.current;
    if (ulElement) {
      const { scrollTop, clientHeight, scrollHeight } = ulElement;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setOffset((prevOffset) => prevOffset + 10);
      }
    }
  };

  return (
    <ul ref={ulRef} className={styles.ulstyle} onScroll={handleScroll}>
      {data.manualTypedData.length > 0 ? (
        data.manualTypedData.map((item, index) => (
          <SearchCard data={item} index={index} key={item.id || index} />
        ))
      ) : (
        <>
        </>
      )}
    </ul>
  );
};  