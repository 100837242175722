import React from "react";
import styles from "./Team.module.css";

function Team() {
  const teamMembers = [
    {
      name: "Iben Nielsen",
      mail: "in@buildtivity.dk",
      title: "CEO",
      imagePath: "/images/omOs/Ibsen.jpg",
      job: "Salg og administration",
    },
    {
      name: "Simon Flyvbjerg Ledet",
      mail: "sfl@buildtivity.dk",
      title: "CMO",
      imagePath: "/images/omOs/Simon.jpg",
      job: "Branding, Marketing, UI, UX",
    },
    {
      name: "Max Nødskov Møller",
      mail: "mnm@buildtivity.dk",
      title: "CTO",
      imagePath: "/images/omOs/Max.jpg",
      job: "Backend",
    },
    {
      name: "Tobias Borregaard",
      mail: "trb@buildtivity.dk",
      title: "Developer/Co founder",
      imagePath: "/images/omOs/Tobias.jpg",
      job: "Frontend",
    },
  ];

  return (
    <div>
      <h1 className={styles.teamHeader}>Teamet</h1>
      <div className={styles.teamContainer}>
        {teamMembers.map((member) => (
          <div className={styles.teamMember} key={member.name}>
            <div className={styles.imgCont}>
              <img
                src={member.imagePath}
                alt={member.name}
                className={styles.teamMemberImage}
              />
            </div>
            <div className={styles.teamDetails}>
              <h2 className={styles.teamName}>{member.name}</h2>
              <p className={styles.teamTitle}>{member.title},</p>
              <p className={styles.teamTitle}>{member.job}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
