import React from "react";


import { FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

import styles from "../styles/BottomSection.module.css";

function BottomSection() {
  const szIcon = 50;

  const logos = [
    {
      id: "LinkedIn",
      icon: (
        <FaLinkedin size={szIcon - 7} className={styles.BottomSection__icon} />
      ),
      link: "https://www.linkedin.com/company/buildtivity",
    },
    {
      id: "Mail",
      icon: <FaEnvelope size={szIcon - 7} className={styles.BottomSection__icon} />,
      link: "mailto:info@buildtivity.dk",
    },
    {
      id: "Instagram",
      icon: <FaInstagram size={szIcon - 7} className={styles.BottomSection__icon} />,
      link: "https://www.instagram.com/buildtivity/",
    },
  ];

  return (
    <div className={styles.BottomSection__container} >
      <div className={styles.BottomSection__textBlock} >
        <h2 className={styles.BottomSection__header} >Følg os her</h2>
        <div  className={styles.BottomSection__logoGrid} >
          {logos.map((logo, index) => (
            <div key={index}>
              <a href={logo.link} target="_blank" rel="noopener noreferrer">
                {logo.icon}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
