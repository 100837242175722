import React, { useState } from "react";
import styles from "./Checkbox.module.css";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const Checkbox = ({ label, checked, onChange }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={onChange}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.checkboxWrapper}
    >
      <div className={styles.checkboxIcon}>
        {checked || hover ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
      </div>
      <label className={styles.label}>{label}</label>
    </div>
  );
};

export default Checkbox;
