import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './footer.module.css';
import { FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

function Feeter() {
  const navigate = useNavigate();
const logo = '/images/hovedelogo.svg';
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        
        {/* Contact Section */}
        <div className={styles.footerSection}>
          <h4>Kontakt</h4>
          <ul className={styles.iconList}>
            <li>
              <a href="https://www.linkedin.com/company/buildtivity" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className={styles.icon} />
              </a>
            </li>
            <li>
              <a href="mailto:info@buildtivity.dk">
                <FaEnvelope className={styles.icon} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/buildtivity/" target="_blank" rel="noopener noreferrer">
                <FaInstagram className={styles.icon} />
              </a>
            </li>
          </ul>
        </div>

        {/* About Us Section
        <div className={styles.footerSection}>
          <h4>Om os</h4>
          <ul>
            <li><span onClick={() => navigate('/vision')} className={styles.footerLink}>Vision</span></li>
            <li><span onClick={() => navigate('/team')} className={styles.footerLink}>Team</span></li>
          </ul>
        </div> */}

        {/* Shortcuts Section */}
        <div className={styles.footerSection}>
          <h4>Genveje</h4>
          <ul>
            <li><span onClick={() => navigate('/om_os')} className={styles.footerLink}>Om os</span></li>
            {/* <li><span onClick={() => navigate('/faq')} className={styles.footerLink}>Frequently Asked Questions</span></li> */}
            <li><span onClick={() => navigate('/vilkaar')} className={styles.footerLink}>Priser og vilkår</span></li>
          </ul>
        </div>

        {/* Logo and Address Section */}
        <div className={styles.footerSection}>
          <img src={logo} alt="Buildtivity Logo" className={styles.footerLogo} />
          <p className={styles.footerText}>
            Hadsundvej 86C 2 t.v<br />9000 Aalborg,<br />Denmark
          </p>
          <p className={styles.footerText}>
            +45 24 21 60 66<br />CVR nr: 43976486
          </p>
        </div>

      </div>
    </footer>
  );
}

export default Feeter;
