import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FeedbackAPI } from './APIS/FeedbackAPI';
import styles from './FeedbackModal.module.css';


const FeedbackModal = ({ closeModal }) => {
  const [title, setTitle] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(''); // State for thank you message
  const location = useLocation();
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setThankYouMessage('Tak for at give din feedback!'); // Always set thank you message
  
    const params = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(params.entries()); // Convert params to an object
  
    // Create extra metadata as a formatted string
    const additionalInfo = `
    Full URL: ${location.pathname}${location.search}
    Path: ${location.pathname}
    User Agent: ${navigator.userAgent}
    Timestamp: ${new Date().toISOString()}
    Referrer: ${document.referrer || 'Direct'}
    User: ${localStorage.getItem('sessionToken')} ? user : 'Anonymous'}
    `;
  
    const feedbackData = {
      title,
      feedback: `${feedback},\n ${additionalInfo}`, 
    };
  
    try {
      await FeedbackAPI(feedbackData.title, feedbackData.feedback);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        closeModal();
      }, 800); // Close modal after a short delay
    }
  };
  

  // Close modal when clicking outside the content
  const handleOverlayClick = () => {
    closeModal();
  };

  // Prevent closing when clicking inside the content
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.feedbackModalOverlay} onClick={handleOverlayClick}>
      <div className={styles.feedbackModalContent} onClick={handleContentClick}>
        <h2>Giv Feedback</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </label>
          <label>
            Feedback:
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            ></textarea>
          </label>
          <div className={styles.modalButtons}>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <button type="button" onClick={closeModal} disabled={isSubmitting}>
              Cancel
            </button>
          </div>
        </form>
        {thankYouMessage && <p className={styles.thankYouMessage} >{thankYouMessage}</p>}
      </div>
    </div>
  );
};

export default FeedbackModal;
