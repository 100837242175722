import styles from "./FeatureBar.module.css";

function FeatureBar() {
  const features = [
    { icon: "images/omOs/compare-icon.svg", text: "Sammenlign Data" },
    { icon: "images/omOs/reliable-icon.svg", text: "Pålidelig information" },
    { icon: "images/omOs/save-icon.svg", text: "Spar Tid" },
  ];

  return (
    <div className={styles.featureBarContainer}>
      {features.map((feature, index) => (
        <div key={index} className={styles.featureItem}>
          <img src={feature.icon} className={styles.icon} />
          <p className={styles.text}>{feature.text}</p>
        </div>
      ))}
    </div>
  );
}

export default FeatureBar;
