import { baseUrl } from "../../GlobalContext";
import { Navigate } from 'react-router-dom';




export const getprofileinfo = async () => {
  try {
    const response = await fetch(`${baseUrl}/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
      }
    });
    if (response.status === 401) {
      localStorage.removeItem('sessionToken');
      return {};

    }

    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching projects for SessionToken ${localStorage.getItem('sessionToken')}:`, error);
    return [];
  }
}



