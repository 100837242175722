import React from "react";
import PropTypes from "prop-types";
import styles from "./Slider.module.css";

const Slider = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  label,
  number,
  initialValue = 0,
  useScientific = false,
}) => {
  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    if (onChange) onChange(newValue);
  };

  const displayNumber =
    number !== null && number !== undefined
      ? parseFloat(number)
      : initialValue ?? 0;

  const formattedNumber = useScientific
    ? displayNumber.toExponential(1)
    : displayNumber.toFixed();

  return (
    <div className={styles.slidercontainer}>
      {label && <label style={{minWidth:"50px",display:"flex", justifyContent:"center"}}>{label}</label>}
      <input
        className={styles.sliderstyle}
        type="range"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
      />
      <div
        className={styles.numbercontainer}
        title={useScientific ? displayNumber.toFixed(2) : formattedNumber}
      >
        <div style={{ width: "60px" }}>{formattedNumber}</div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  number: PropTypes.number,
  initialValue: PropTypes.number,
  useScientific: PropTypes.bool,
};

export default Slider;
