import styles from "./safetyexpl.module.css";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { useState, useEffect } from "react";

const Card = ({ info }) => {
  return (
    <div className={styles.card}>
      <div>
        <h4 className={styles.cardTitle}>{info.level}</h4>
      </div>
      <div className={styles.cardContent}>
        <img src={info.icon} alt="logo" className={styles.logo} />
        <div className={styles.cardText}>
          <h2 className={styles.cardHeader}>{info.header}</h2>
          <p className={styles.cardText}>{info.text}</p>
        </div>
      </div>
    </div>
  );
};

function SafetyLevelExplainer() {
  const cardInfo = {
    level0: {
      header: "AI behandlet data",
      level: "Level 0",
      text: "Dokumentet er blevet behandlet af vores AI-model, som har udtrukket og omregnet dataene. Bemærk, at resultaterne ikke er verificeret yderligere og bør gennemgåes for endelig validering.",
      icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 0.svg`,
    },
    level1: {
      header: "Data direkte fra datablade",
      level: "Level 1",
      text: "Dataene er udtrukket direkte fra databladene og præsenteres i deres oprindelige form udenomregning eller yderligere behandling. Dette svarer til at læse informationen direkte fra databladene.",
      icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 1.svg`,
    },
    level2: {
      header: "Data verificeret af producent",
      level: "Level 2",
      text: "Dataene er udtrukket direkte fra databladene og verificeret af producenterne. Dette sikrer, at informationen er pålidelig og i overensstemmelse med originalkilden.",
      icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 2.svg`,
    },
    level3: {
      header: "Data verificeret af Builditivity",
      level: "Level 3",
      text: "Dataene er blevet verificeret af Buildtivity, hvilket sikrer høj kvalitet både i de forskellige enhedsomregninger og i de udtrukne oplysninger fra databladene.",
      icon: `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level 3.svg`,
    },
  };

  const cardGroups = [
    [cardInfo.level0, cardInfo.level1],
    [cardInfo.level2, cardInfo.level3],
  ];

  const [currentGroup, setCurrentGroup] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth < 768);
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleNextGroup = () => setCurrentGroup((prev) => (prev === 0 ? 1 : 0));
  const handlePreviousGroup = () =>
    setCurrentGroup((prev) => (prev === 0 ? 1 : 0));

  return (
    <div className={styles.container}>
      <h1>Fuld Gennemsigtighed</h1>
      <p className={styles.description}>
        Sådan behandler vi dataene. Hver materiale har et level:
        <span> Level 0 - </span>
        <span> Level 1 - </span>
        <span> Level 2 - </span>
        <span> Level 3</span>
      </p>

      <div className={styles.wrapper}>
        {!isMobile && (
          <SlArrowLeft className={styles.arrow} onClick={handlePreviousGroup} />
        )}

        <div
          className={`${styles.cardContainer} ${
            isMobile ? styles.mobileStack : ""
          }`}
        >
          {isMobile ? (
            /* Show all 4 cards in a stack on mobile */
            <>
              {Object.values(cardInfo).map((info, i) => (
                <Card key={i} info={info} />
              ))}
            </>
          ) : (
            /* Desktop: Show only 2 at a time */
            <div
              className={styles.slidingContainer}
              style={{ transform: `translateX(-${currentGroup * 100}%)` }}
            >
              {cardGroups.map((group, index) => (
                <div key={index} className={styles.group}>
                  {group.map((info, i) => (
                    <Card key={i} info={info} />
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>

        {!isMobile && (
          <SlArrowRight className={styles.arrow} onClick={handleNextGroup} />
        )}
      </div>
    </div>
  );
}

export default SafetyLevelExplainer;
