import React from 'react';
import styles from './mainpage.module.css';
import { link } from 'superagent';

function Banner() {
  const logos = [
    // "https://lendager.com/wp-content/uploads/2021/05/Lendager_Logo_Primary_RGB_Black.png",
    {url:"https://cyberpartners.dk/wp-content/uploads/2024/05/Logo-Light-1.png",link:"https://cyberpartners.dk/"},
    {url:"https://projekt.obel.com/images/obel2.png",link:"https://obel.com/"},
    // "https://bk-nord.dk/wp-content/themes/bknord/public/images/bknord.99b8bc.svg",
    {url:"https://undervisningsportalen.imgix.net/iwmdfput/mikrolegat_logo_3.png?ixlib=vue-2.9.1&fit=max&w=250&q=70&enhance=true&auto=format&fm=webp&dpr=2", link: "https://mikrolegat.ffefonden.dk/"},
    // "https://karakterarkitektur.dk/wp-content/uploads/2024/04/ka-logo-sort-lille.svg",
    // "https://cad-tegnestuen.dk/wp-content/uploads/2018/08/icon-1-1-e1535101837555.png",
  ];

  return (
    <div className={styles.banner}>
        <h2>Udviklet i samarbejde med:</h2>
      <div className={styles.slider}>
        {logos.map((logoUrl, index) => {
          return (
            <a
              key={`original-${index}`}
              href={logoUrl.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {logoUrl.url.includes(".svg") ? (
                <iframe
                  src={logoUrl}
                  title={`Logo ${index + 1}`}
                  className={styles.logo}
                  style={{ border: "none" }}
                />
              ) : (
                <img
                  src={logoUrl.url}
                  alt={`Logo ${index + 1}`}
                  className={styles.logo}
                />
              )}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Banner;
