import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineLock, AiOutlineUnlock, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { IoPersonSharp } from 'react-icons/io5';
import { MdCollectionsBookmark } from 'react-icons/md';
import { useGlobalContext } from '../../GlobalContext';
import { getprofileinfo } from '../APIS/ProfileAPI';
import styles from './navbar.module.css'; // Import the CSS module

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('sessionToken'));
  // const isLoggedIn = !!localStorage.getItem('sessionToken');
  const { userType, setUserType } = useGlobalContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProd, setIsProd] = useState(userType?.canEditProducerData === true);
  const [usePlatform, setUsePlatform] = useState(userType?.canUsePlatform === true);
  const [activeNavLink, setActiveNavLink] = useState('');
  useEffect(() => {
    setActiveNavLink(location.pathname);
  }, [location.pathname]);

  const isActive = (path) => {
    return activeNavLink === path || location.pathname.startsWith(`${path}`);
  };


  useEffect(() => {
    setIsProd(userType?.canEditProducerData === true);
    setUsePlatform(userType?.canUsePlatform === true);
    setIsLoggedIn(!!localStorage.getItem('sessionToken'));
  }, [userType]);

  const handleLogout = () => {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('selectedProjects');
    setUserType({});
    navigate('/login');
  };


  useEffect(() => {
    if (userType === null || userType === undefined || Object.keys(userType).length === 0) {
      getprofileinfo().then((data) => {

        setUserType(data);
      }
      );
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    // Add event listener on mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine if the screen is small
  const isMobile = windowWidth <= 1100;





  const links = [
    { path: '/', label: 'Hjem' },
    isLoggedIn && usePlatform ? { path: '/kategori', label: 'Kategori' } : null,
    { path: '/om_os', label: 'Om Os' },
  ].filter(Boolean);

  const renderMenu = () => {
    const isActive = (path, label) => location.pathname === path || location.pathname.startsWith(`${path}/`);

    return (
      <div
        className={`${styles.menu} ${isMenuOpen ? styles.menuOpen : ''}`}
        onClick={() => setIsMenuOpen(false)} // Close menu when clicking outside
      >
        <ul
          className={styles.linksContainer}
          onClick={(e) => e.stopPropagation()}
        >
          {links.map((link) => (
            <li key={link.path} className={styles.linkItem}>
              <NavLink
                to={link.path}
                className={
                  isActive(link.path, link.label)
                    ? `${styles.navLink} ${styles.activeNavLink}`
                    : styles.navLink
                }
                onClick={() => setIsMenuOpen(false)}
              >
                {link.label}
              </NavLink>
            </li>
          ))}

          {isLoggedIn ? (
            <>
              {isProd && (
                <li className={styles.linkItem}>
                  <NavLink
                    to="/produkter"
                    className={
                      isActive("/produkter")
                        ? `${styles.navLink} ${styles.activeNavLink}`
                        : styles.navLink
                    }
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Mine produkter
                  </NavLink>
                </li>
              )}

              {usePlatform && (
                <li className={styles.linkItem}>
                  <NavLink
                    to="/projekter"
                    className={
                      isActive("/projekter")
                        ? `${styles.navLink} ${styles.activeNavLink}`
                        : styles.navLink
                    }
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Mine projekter
                  </NavLink>
                </li>
              )}

              <li className={styles.linkItem}>
                <NavLink
                  to="/"
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className={styles.navLink
                  }
                >
                  Log ud
                </NavLink>
              </li>
            </>
          ) : (
            <li className={styles.linkItem}>
              <NavLink
                to="/login"
                className={
                  isActive("/login")
                    ? `${styles.navLink} ${styles.activeNavLink}`
                    : styles.navLink
                }
                onClick={() => setIsMenuOpen(false)}
              >
                Login
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <>

      <nav className={styles.nav}>
        <div>
          <img src="/images/LongLogo.png" alt="Logo" className={styles.logo} />
        </div>
        {isMobile ? (
          <>
            <div className={styles.menuButton} onClick={toggleMenu}>
              <div className={styles.iconContainer}>
                {isMenuOpen ? (
                  <AiOutlineClose size={48} color="var(--Sand)" />
                ) : (
                  <AiOutlineMenu size={48} color="var(--Sand)" />
                )}
              </div>
            </div>
            {isMenuOpen && renderMenu()}
          </>
        ) : (
          <div className={styles.linkAndNavs}>
            <ul className={styles.linksContainer}>
              {links.map((link) => (
                <li key={link.path} className={styles.linkItem}>
                  <NavLink
                    to={link.path}
                    className={
                      isActive(link.path) && link.label !== 'Hjem'
                        ? `${styles.navLink} ${styles.activeNavLink}`
                        : styles.navLink
                    }
                  >
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
            {isLoggedIn && (
              <>
                <div className={styles.navButtons}>
                  {isProd && (
                    <div>
                      <NavLink
                        to="/produkter"
                        className={
                          isActive("/produkter")
                            ? `${styles.button} ${styles.activeLink}`
                            : styles.button
                        }
                      >
                        <MdCollectionsBookmark className={styles.icon} size={20} />
                        Mine Produkter
                      </NavLink>
                    </div>
                  )}
                  {usePlatform && (
                    <NavLink
                      to="/projekter"
                      className={
                        isActive("/projekter")
                          ? `${styles.button} ${styles.activeLink}`
                          : styles.button
                      }
                    >
                      <MdCollectionsBookmark className={styles.icon} size={20} />
                      Mine Projekter
                    </NavLink>
                  )}
                  <div>
                    <NavLink
                      to="/"
                      onClick={handleLogout}
                      className={styles.button}
                    >
                      <AiOutlineUnlock className={styles.icon} size={24} />
                      Log Ud
                    </NavLink>
                  </div>
                </div>
              </>
            )}
            {!isLoggedIn && (
              <div className={styles.loginBtn}>
                <NavLink
                  to="/login"
                  className={
                    isActive("/login")
                      ? `${styles.button} ${styles.activeLink}`
                      : styles.button
                  }
                >
                  <AiOutlineLock className={styles.icon} size={24} />
                  Login
                </NavLink>
              </div>
            )}
          </div>
        )}
      </nav>
      {(location.pathname.startsWith('/kategori') || location.pathname.startsWith('/subkategori') || location.pathname.startsWith('/produkt/') || location.pathname.startsWith('/search') ) && (
        <div className={styles.betaBanner}>
          <span >Produktet er under udvikling - Inkorrekte værdier kan forekomme</span>
        </div>
      )}

    </>
  );


}

export default Navbar;
