import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchFromReg,
  fetchSubcategories,
  fetchAllCategories,
} from "../components/APIS/categoryAPI";
import { AnimatePresence, motion } from "framer-motion";
import RadioButtonsGroup from "../components/radiobuttongroup";
import styles from "./styles/KategoriPage.module.css";
import Loader from "../components/Utils/Loader";
import { SearchBarPopup, SearchBar } from "../components/SwitchesAndStuff/searchCard";

function KategoriPage() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false); // Track input focus state
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Initial radio value from URL or default
  const initialRadioValue = searchParams.get("type") || "Konstruktioner";
  const [radioValue, setRadioValue] = useState(initialRadioValue);

  // Update search params when radio value changes
  useEffect(() => {
    setSearchParams({ type: radioValue, search: inputValue });
  }, [radioValue, setSearchParams, setInputValue]);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchAllCategories();
        if (!response) {
          throw new Error("Failed to fetch categories");
        }
        setCategories(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Fetch subcategories
  useEffect(() => {
    fetchSubcategories()
      .then((data) => {
        setSubcategories(data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getImageByCategory = (category) => {
    const toCamelCase = (str) => {
      if (/^[a-z]+([A-Z][a-z]*)*$/.test(str)) {
        return str;
      }
      return str
        .toLowerCase()
        .replace(/[_\s-](.)/g, (match, group1) => group1.toUpperCase())
        .replace(/[_\s-]/g, "")
        .replace(/^(.)/, (match, group1) => group1.toLowerCase());
    };
    return `/images/kategori_billeder/${toCamelCase(category)}.svg`;
  };

  const handleBoxClick = (title) => {
    navigate(`/kategori/${title}`, { state: { category: title } });
  };
  const handleBoxsubClick = (title) => {
    navigate(`/subkategori/${title}`, { state: { category: title } });
  };

  const formatSubcategory = (name) => {
    if (typeof name !== "string") return name;
    return name
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };
  

  const handleInputChange = (e) => setInputValue(e.target.value);


  const boxes = categories.map((title, index) => (
    <div key={index} className={styles.box}>
      <img
        src={getImageByCategory(title)}
        className={styles.boxImg}
        alt={title}
        onClick={() => handleBoxClick(title)}
      />
      <h3 className={styles.boxTitle} onClick={() => handleBoxClick(title)}>
        {formatSubcategory(title)}
      </h3>
    </div>
  ));

  const materialContent = () => {
    if (!subcategories || subcategories.length === 0) return <p>No data</p>;
    return (
      <div className={styles.materialColumns}>
        {subcategories
          .filter((material) =>
            typeof material === "string" &&
            !material.toLowerCase().startsWith("sub") &&  // Convert to lowercase before checking
            material.trim()
          )
          .map((material, index) => (
            <div key={index} className={styles.materialColumn}>
              <p onClick={() => handleBoxsubClick(material)}>
                {formatSubcategory(material)}
              </p>
            </div>
          ))}


      </div>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form refresh
    if (inputValue.trim()) {
      navigate(`/search/${encodeURIComponent(inputValue)}`); // Navigate to the search page
    }
  };

  return (
    <div
      className={`${styles.mainContainer} ${isFocused ? styles.blurred : ""}`}
      style={{ paddingBottom: "20px" }}
    >
      {/* Overlay for dimmed background */}
      {isFocused && (
        <div
          className={styles.overlay}
          onClick={() => setIsFocused(false)} // Corrected syntax for the onClick handler
        ></div>
      )}


      <div className={styles.pageKategoriContainer}>
        <img
          src="/images/hovedelogo.svg"
          alt="logo"
          className={styles.logoContainer}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <form style={{ width: "80%" }} onSubmit={handleSubmit} >

            <input
              type="text"
              placeholder="Søg efter produkter"
              className={styles.searchInput}
              value={inputValue}
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
            />
          </form>
          {/* <SearchBar
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            setIsFocused={setIsFocused}
            /> */}
          <div
            style={{
              position: "absolute",
              top: "75%",
              left: "50%",
              width: "80%",
              height: "auto",
              transform: "translateX(-50%)",
              zIndex: "1000",
            }}
          >
            {(inputValue !== "" && isFocused) && (
              <SearchBarPopup query={fetchFromReg} search={inputValue} />
            )}
          </div>
        </div>
      </div>

      <div className={styles.searchbarAndRadio}>
        <RadioButtonsGroup
          options={["Konstruktioner", "Materialer"]}
          selectedValue={radioValue}
          handleRadioChange={(e) => setRadioValue(e.target.value)}
        />
      </div>

      <AnimatePresence mode="wait">
        {radioValue === "Konstruktioner" ? (
          <motion.div
            key="konstruktioner"
            style={{ paddingTop: "15px" }}
            className={styles.boxesContainer}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? <Loader /> : boxes}
          </motion.div>
        ) : (
          <motion.div
            key="materialContent"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? <p>Loading materials...</p> : materialContent()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default KategoriPage;
