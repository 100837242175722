import React, { useState } from 'react';
import { baseUrl} from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
// export default ResetPasswordPage;
import styles from './LoginPage.module.css'; // Import CSS module

// function ResetPasswordPage() {
//     const [email, setEmail] = useState('');
//     const [oldPassword, setOldPassword] = useState('');
//     const [newPassword, setNewPassword] = useState('');
//     const [repeatNewPassword, setRepeatNewPassword] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const navigate = useNavigate();

//     const handleResetPassword = async (e) => {
//         e.preventDefault();
//         setErrorMessage('');

//         if (newPassword !== repeatNewPassword) {
//             setErrorMessage('New passwords do not match');
//             return;
//         }

//         try {
//             const response = await fetch(`${baseUrl}/User/reset-password`, {
//                 method: 'PUT', // Changed from 'POST' to 'PUT'
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     Email: email, // Ensure property names match backend expectations
//                     OldPassword: oldPassword,
//                     NewPassword: newPassword,
//                 }),
//             });

//             let data;
//             const contentType = response.headers.get('content-type');

//             if (contentType && contentType.includes('application/json')) {
//                 data = await response.json();
//             } else {
//                 data = await response.text();
//             }

//             if (response.ok) {
//                 // Navigate to the login page on successful password reset
//                 navigate('/login');
//             } else {
//                 // Display error message from backend or a generic message
//                 setErrorMessage(data || 'Failed to reset password');
//             }
//         } catch (error) {
//             console.error('Failed to reset password:', error);
//             setErrorMessage('An error occurred while trying to reset the password. Please try again.');
//         }
//     };

//     return (
//         <div className={styles.pageStyle}>
//             <div className={styles.loginCont}>
//                 <div className={styles.logInputStyle}>
//                     <form onSubmit={handleResetPassword}>
//                         <h3 className={styles.fontStyle}>Email</h3>
//                         <input
//                             type="email"
//                             name="email"
//                             placeholder="Email"
//                             value={email}
//                             onChange={(e) => {
//                                 setEmail(e.target.value);
//                                 setErrorMessage('');
//                             }}
//                             className={styles.inputStyle}
//                         />
//                         <h3 className={styles.fontStyle}>Old Password</h3>
//                         <input
//                             type="password"
//                             name="oldPassword"
//                             placeholder="Old Password"
//                             value={oldPassword}
//                             onChange={(e) => {
//                                 setOldPassword(e.target.value);
//                                 setErrorMessage('');
//                             }}
//                             className={styles.inputStyle}
//                         />
//                         <h3 className={styles.fontStyle}>New Password</h3>
//                         <input
//                             type="password"
//                             name="newPassword"
//                             placeholder="New Password"
//                             value={newPassword}
//                             onChange={(e) => {
//                                 setNewPassword(e.target.value);
//                                 setErrorMessage('');
//                             }}
//                             className={styles.inputStyle}
//                         />
//                         <h3 className={styles.fontStyle}>Repeat New Password</h3>
//                         <input
//                             type="password"
//                             name="repeatNewPassword"
//                             placeholder="Repeat New Password"
//                             value={repeatNewPassword}
//                             onChange={(e) => {
//                                 setRepeatNewPassword(e.target.value);
//                                 setErrorMessage('');
//                             }}
//                             className={styles.inputStyle}
//                         />
//                         <div className={styles.loginContButtons}>
//                             <button type="submit" className={styles.buttonStyle}>Reset Password</button>
//                         </div>
//                         {/* Display the error message */}
//                         {errorMessage && <p className={styles.loginError}>{errorMessage}</p>}
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// }


function ResetPasswordPage() {
    return (
        <div className={styles.pageStyle}>
            <div className={styles.loginCont}>
                <div className={styles.logInputStyle}>
                    <h2 className={styles.fontStyle}>Nulstil adgangskode</h2>
                    <p className={styles.resetMessage}>
                        For nulstilling send mail til <a href="mailto:mnm@buildtivity.dk">mnm@buildtivity.dk</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordPage;
