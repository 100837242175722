import React, { createContext, useState, useContext, version } from 'react';
import Cookies from 'js-cookie';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(JSON.parse(localStorage.getItem('selectedProjects')) || []);
  const [userType, setUserType] = useState({});
  const [cookies, setCookie] = useState(() => {
    const storedCookies = Cookies.get('cookieConsent');
    return storedCookies ? JSON.parse(storedCookies) : { accept: false, version: 0 };
}); 

  


  return (
    <GlobalContext.Provider value={{ selectedProducts, setSelectedProducts, selectedProjects, setSelectedProjects,userType, setUserType, cookies, setCookie }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
}

export const baseUrl = process.env.REACT_APP_API_URL;

