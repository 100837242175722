import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../GlobalContext";
import Cookies from "js-cookie";
import Styles from "./cookie.module.css";
import { CheckboxToggle } from "../SwitchesAndStuff/CheckboxToggle";
import { ButtonBuild } from "../SwitchesAndStuff/Button";

function CookiesBanner() {
    const context = useGlobalContext();
    const { cookies, setCookie } = context || { cookies: null, setCookie: null };

    // Default cookie settings with categories
    const defaultCookieSettings = {
        accept: false,
        version: 1,
        categories: {
            necessary: true,  // Always enabled
            functional: false,
            analytics: false,
            marketing: false,
        }
    };

    // Load cookie preferences on component mount
    useEffect(() => {
        if (setCookie) {
            const storedCookieConsent = Cookies.get("cookieConsent");
            if (storedCookieConsent) {
                setCookie(JSON.parse(storedCookieConsent));
            } else {
                setCookie(defaultCookieSettings);
            }
        }
    }, [setCookie]);

    // State to handle user preferences
    const [preferences, setPreferences] = useState(cookies?.categories || defaultCookieSettings.categories);

    // Handle category toggle
    const handlePreferenceChange = (category) => {
        setPreferences((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    // Accept all cookies
    const acceptAllCookies = () => {
        const updatedCookies = {
            accept: true,
            version: 1,
            categories: {
                necessary: true,
                functional: true,
                analytics: true,
                marketing: true,
            }
        };
        setCookie(updatedCookies);
        console.log("Accept all cookies", updatedCookies);
        Cookies.set("cookieConsent", JSON.stringify(updatedCookies), { expires: 365 });
    };

    // Reject all non-essential cookies
    const rejectAllCookies = () => {
        const updatedCookies = {
            accept: false,
            version: 1,
            categories: {
                necessary: true,
                functional: false,
                analytics: false,
                marketing: false,
            }
        };
        setCookie(updatedCookies);
        Cookies.set("cookieConsent", JSON.stringify(updatedCookies), { expires: 365 });
    };

    // Save user-selected preferences
    const savePreferences = () => {
        const updatedCookies = {
            accept: true,
            version: 1,
            categories: preferences,
        };
        setCookie(updatedCookies);
        Cookies.set("cookieConsent", JSON.stringify(updatedCookies), { expires: 365 });
    };

    const renderCookieOption = (label, category) => (
        <div key={category} className={Styles.cookieCategory}>
            <strong>{label}</strong>
            <CheckboxToggle
                id={category}
                checked={preferences[category]}
                onChange={() => handlePreferenceChange(category)}
            />
        </div>
    );
    

    return (
        (!cookies?.accept) && (
            <div className={Styles.cookieOverlay}>
                <div className={Styles.cookieModal}>
                    <h3 className={Styles.cookieTitle}>Customize Consent Preferences</h3>
                    <p className={Styles.cookieDescription}>
                        We use cookies to enhance your experience. Some cookies are necessary, while others help us improve the website.
                    </p>

                    {/* Necessary cookies - always active */}
                    <div className={Styles.cookieSection}>
                        <div className={Styles.cookieCategory}>
                            <strong>Necessary</strong>
                            <span className={Styles.alwaysActive}>Always Active</span>
                        </div>
                        <p className={Styles.cookieText}>
                            Necessary cookies are required to enable core functionalities such as security and accessibility.
                        </p>
                    </div>

                    {/* Render the toggle switches */}
                    {renderCookieOption("Functional", "functional")}
                    {renderCookieOption("Analytics", "analytics")}
                    {renderCookieOption("Marketing", "marketing")}

                    <div className={Styles.buttonContainer}>
                        {/* <button onClick={rejectAllCookies} className={Styles.rejectButton}>Reject All</button>
                        <button onClick={savePreferences} className={Styles.saveButton}>Save My Preferences</button>
                        <button onClick={acceptAllCookies} className={Styles.acceptButton}>Accept All</button> */}
                        <ButtonBuild id="rejectAll" onClick={rejectAllCookies} label="Reject All" />
                        <ButtonBuild id="savePreferences" onClick={savePreferences} label="Save My Preferences" />
                        <ButtonBuild id="acceptAll" onClick={acceptAllCookies} label="Accept All" />
                    </div>
                </div>
            </div>
        )
    );

}

export default CookiesBanner;
