




import React from 'react';
import Styles from './vilkaar.module.css';

function Vilkaar() {



    return (
       <>
         <div className={Styles.vilkaarContainer}>
            <div className={Styles.vilkaarContent}>
            <h2>Vilkår og betingelser</h2>
            <p>Her kan du læse om vores vilkår og betingelser</p>
            
            </div>
            <div className={Styles.vilkaarContent}>
            <h2>1. Generelt</h2>
            <p>Disse vilkår og betingelser gælder for alle aftaler, der indgås mellem dig og os. Vilkårene gælder for alle leverancer fra os, uanset om du er forbruger eller erhvervsdrivende.</p>
            </div>
            <div className={Styles.vilkaarContent}>
            <h2>2. Priser</h2>
            <p>Alle priser er inkl. moms og andre afgifter. Priserne er dagspriser. Der kan forekomme prisændringer.</p>
            </div>
          

         </div>

       
       
       </>
    );
    }

export default Vilkaar;